<template>
  <v-card class="rounded-xl pa-6 text-center login-page" elevation="0" outlined>
    <v-form lazy-validation v-model="valid" @submit.prevent="submit">
      <h1>Inloggen</h1>

      <v-text-field aria-autocomplete="email" label="Email" class="theme-input" background-color="#f1f8fa"
        :rules="rules.mail" v-model="authdata.email" validate-on-blur solo></v-text-field>
      <v-text-field aria-autocomplete="current-password" solo background-color="#f1f8fa" v-model="authdata.passwd"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="rules.pass" :type="show ? 'text' : 'password'"
        label="Wachtwoord" class="theme-input" @click:append="show = !show" validate-on-blur></v-text-field>
      <v-row>
        <v-col cols="12"><v-btn text to="/wachtwoord-vergeten">Wachtwoord vergeten?</v-btn></v-col>
        <v-col cols="0" md="4"></v-col>
        <v-col cols="12" md="2"><v-btn class="mx-auto d-block btn" color="themewhite" to="/auth">Terug</v-btn></v-col>
        <v-col cols="12" md="2"> <v-btn class="text-white d-block w-100 mx-auto btn" color="themepurple" type="submit" :loading="loading">
            Inloggen</v-btn></v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import api from "../../services/api";
import config from '@/config';
import * as cookies from "js-cookie";
export default {
  components: {},
  data: () => ({
    config: config,
    loading: false,
    busy: false,
    valid: false,
    show: false,
    rules: {
      mail: [
        (v) => !!v || "Verplicht",
        (v) => config.regex.mail.test(v) || "Incorrect emailadres",
        (v) => v.split('@', 2)[1]?.includes(".") || "Domeinextensie ontbreekt",
      ],
      pass: [
        v => !!v || 'Verplicht',
        v => (v && v.length >= 2) || 'Minimaal 8 tekens',
        v => (v && v.length <= 64) || 'Maximaal 64 tekens',
        v => (/[a-z]/.test(v)) || 'Wachtwoord moet ten minste één kleine letter bevatten',
        v => (/[A-Z]/.test(v)) || 'Wachtwoord moet minstens één hoofdletter bevatten',
        v => (/[0-9]/.test(v)) || 'Wachtwoord moet ten minste één getal bevatten',
        v => (/[@$!#%*?&]/.test(v)) || 'Wachtwoord moet ten minste een van de volgende speciale tekens bevatten: @$!%*?&',
        v => config.regex.password.test(v) || 'Het wachtwoord moet tussen 8 en 64 tekens lang zijn en ten minste één hoofdletter, kleine letter, cijfer en één van de volgende speciale tekens bevatten: @$!%*?&',
      ],
    },
    authdata: {
      email: "",
      passwd: "",
    },
  }),

  methods: {
    submit() {
      if (this.valid) {
        this.loading = true;
        api.post(`${config.url.api}/v1/auth/step`, this.authdata).then((res) => {
          if (res.data.t === 1) {
            cookies.set("jh_refresh_token", res.data.refresh_token, {
              domain: config.cookiedomain
            });
            cookies.set("jh_access_token", res.data.access_token, {
              domain: config.cookiedomain
            });

            cookies.set("user", res.data.u, {
              domain: config.cookiedomain
            });
            if (Object.prototype.hasOwnProperty.call(this.$route.query, 'r')) {
              let url = new URL(this.$route.query.r);
              if (config.allowedredirectdomains.includes(url.hostname.search(/[^.]*\.[^.]{2,3}(?:\.[^.]{2,3})?$/)[0])) {
                location.replace(this.$route.query.r)
              } else {
                location.replace(`${config.url.main}`)
              }
            } else {
                location.replace(`${config.url.main}`)
            }
          } else if (res.data.s == 49) {
            this.loading = false;
            this.$toast.error('Onjuist emailadres en/of wachtwoord!')
          }
        }).catch((err) => {
          switch (err.response.status) {
            case 401:
              this.$toast.error('Onjuist emailadres en/of wachtwoord!')
              break;
            default:
              this.$toast.error(`Er is iets fout gegaan (${err.response.status})`)
              break;
          }
          this.loading = false
        })
      }
    }
  },
  created() {
    if (cookies.get('jh_refresh_token') && cookies.get('user')) {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'r')) {
        let url = new URL(this.$route.query.r);
        if (config.allowedredirectdomains.includes(url.hostname)) {
          location.replace(this.$route.query.r)
        } else {
          this.$router.push("/")
        }
      } else {
        this.$router.push("/")
      }
    }
  }
};
</script>